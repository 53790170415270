import React, { useEffect, useContext, useState } from "react"
import ReactModal from 'react-modal'
import "./comboWrapper.scss"
import { string_translation } from "../../../utils"
import {useStaticQuery, graphql} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import SliderNavigation from '../../components/SharedComponents/SliderNavigation'

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

import { createMarkup } from "../../../utils"

export default function ComboWrapper(props) {

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      videos_ar:translateString(language: AR, string: "VIDEOS")
      videos_en:translateString(language: EN, string: "VIDEOS")
      gallery_ar:translateString(language: AR, string: "GALLERY")
      gallery_en:translateString(language: EN, string: "GALLERY")
      map_ar:translateString(language: AR, string: "MAP")
      map_en:translateString(language: EN, string: "MAP")
      popup_box_ar:translateString(language: AR, string: "التقديم على هذه القطعة يتطلب مراجعة الأمانة")
      popup_box_en:translateString(language: EN, string: "Applying to this Land requires referring to the Riyadh Municipality")
      amana_map_ar:translateString(language: AR, string:"البوابة المكانية")
      amana_map_en:translateString(language: EN, string: "Geoportal")
      geo_portal_heading_ar:translateString(language: AR, string:"البحث عن الدليل الخاص بقطعة الأرض")
      geo_portal_heading_en:translateString(language: EN, string: "Search for the Plot’s Design Code Manual")
      geo_portal_sub_heading_ar:translateString(language: AR, string:"يمكنك الاطلاع على الدليل الخاص بقطعة الأرض من خلال البوابة المكانية\nالخاصة بأمانة منطقة الرياض بالنقر على الأيقونة، ولمزيد من المعلومات يرجى الاطلاع على النقاط أدناه:")
      geo_portal_sub_heading_en:translateString(language: EN, string: "You can view the Plot’s Design Code Manual through the Geoportal of the Riyadh Region \nMunicipality by clicking on the icon. For more information, please see the below points: ")
      geo_portal_ar:translateString(language: AR, string:"البوابة المكانية")
      geo_portal_en:translateString(language: EN, string: "Geo Portal")
      geo_portal_btn_ar:translateString(language: AR, string:"البوابة المكانية  ←   ")
      geo_portal_btn_en:translateString(language: EN, string: "Geo Portal →")
      search_box_ar:translateString(language: AR, string:"خانة البحث")
      search_box_en:translateString(language: EN, string: "Search Box")
      search_box_data_ar:translateString(language: AR, string:"من خلال خانة البحث، قم باختيار 'إضافة معايير البحث' ومن ثم اختيار 'المخططات' من القائمة وإدخال رقم المخطط.")
      search_box_data_en:translateString(language: EN, string: "Select 'Add Search Criteria' from the search box, then 'Plans' from the list and enter the plan number.")
      plot_number_ar:translateString(language: AR, string:"رقم قطعة الأرض")
      plot_number_en:translateString(language: EN, string: "Plot Number")
      plot_number_data_ar:translateString(language: AR, string:"بتكرار العملية السابقة وبعد إدخال رقم المخطط، اضغط على 'إضافة معايير البحث' وإدراج رقم قطعة الأرض.")
      plot_number_data_en:translateString(language: EN, string: "By repeating the previous step and after entering the plan number, select 'Add Search Criteria' and enter the plot number.")
      plot_selection_ar:translateString(language: AR, string:"اختيار قطعة الأرض")
      plot_selection_en:translateString(language: EN, string: "Plot Selection")
      plot_selection_data_ar:translateString(language: AR, string:"بعد الضغط على زر 🔍 ستظهر عدة نتائج، اضغط على القطعة المراد الاستعلام عنها. يمكنك معرفة الدليل بتقريب الخريطة على قطعة الأرض حتى يظهر لك رمز الدليل.")
      plot_selection_data_en:translateString(language: EN, string: "After clicking on 🔍, several results will appear. Then, click on the plot you want to inquire about. You can find out the Manual by zooming the map on the plot until the Design Code Manual’s symbol appears.")
      design_code_manual_ar:translateString(language: AR, string:"الدليل الخاص بقطعة الأرض")
      design_code_manual_en:translateString(language: EN, string: "Design Code Manuals for Plots")
      about_designcode_ar:translateString(language: AR, string:"نبذة عن كود التصميم")
      about_designcode_en:translateString(language: EN, string: "About the Design Code​")
      sustainability_ar:translateString(language: AR, string:"الاستدامة")
      sustainability_en:translateString(language: EN, string: "Sustainability")
    }
  }`)

  const swiperRef = React.useRef(null)
  const [isOpen, setOpen] = useState(false)

  const handleModalOpen = event => {

    setOpen(true)
  }
  const shouldRenderArrows = true;

  const handleModalClose = event => {
    setOpen(false)
  }

  ReactModal.setAppElement('#___gatsby')
  let showModal = true
  let hash="#videos";
  if (typeof window !== 'undefined') {
    try{
  if(sessionStorage.getItem('hash')){
    hash=sessionStorage.getItem('hash');
  }
}catch(ex){
        
}
}
  try{
  if (window.location.hash) {
    hash=window.location.hash;
  }
  }catch(err){
    
  }

  useEffect(
    () => {
      if (hash) {
         setActiveTab(hash)
      }
    }
  )

  function setActiveTab(hashurl){
    var elements = document.getElementsByClassName("listhead")[0].getElementsByTagName("li");
      for (var i = 0; i < elements.length; i++) {
      if(elements[i].dataset.tab==hashurl){
        elements[i].classList.add("active");
      }else{
        elements[i].classList.remove("active");
      }
    }


    var elementsdown = document.getElementsByClassName("listbottom")[0].getElementsByTagName("div");
  
      for (var i = 0; i < elementsdown.length; i++) {
        if(elementsdown[i].id!=""){
      if('#'+elementsdown[i].id==hashurl){
        elementsdown[i].classList.add("active");
      }else{
        elementsdown[i].classList.remove("active");
      }
    }
    }
  }
  function updatehash(hash){
    if (typeof window !== 'undefined') {
      try{
      sessionStorage.setItem('hash', hash);
      }catch(ex){
        
      }
    }
    
  var arabic = document.querySelectorAll(".smail-menu-item,.arabic");
    try{
     for ( let i=0 ; i < arabic.length ; i++) {
      var elem = arabic[i];
       if(elem.childNodes){
       const url = elem.childNodes[0].href;
       const urlObj = new URL(url);
        urlObj.hash = hash;
        const result = urlObj.toString();
       elem.childNodes[0].href=result;
       }

      }
    }catch(aaa){

    }
  }
  return (
    <>
      <section className="combo-wrapper">
        <div className="container">
          <div className="combo-box-tab"> 
            <ul className="tabs tabs-list h2 listhead mobileulList" data-controls="#drf">
              <li data-tab="#videos" onClick={(e) => updatehash('#videos')}   className={hash == '#videos'? 'active': '' }>
                {string_translation(stringTranslations, "about_designcode", locale)}
              </li>
              <li data-tab="#sustainability" onClick={(e) => updatehash('#sustainability')} className={hash == '#sustainability'? 'active': '' }>
                {string_translation(stringTranslations, "sustainability", locale)}
              </li>
              <li data-tab="#the-map" onClick={(e) => updatehash('#the-map')} className={hash == '#the-map'? 'active': '' }>
                {string_translation(stringTranslations, "map", locale)}
              </li>
              <li data-tab="#amana-map" onClick={(e) => updatehash('#amana-map')} className={hash == '#amana-map'? 'active': '' }>
                {string_translation(stringTranslations, "amana_map", locale)}
              </li>
            </ul>
          </div>
          <div className="tab-container listbottom" id="drf">
            <div  id="videos" className={hash == '#videos'? 'active resizing': 'resizing' }>
              
             
              <Swiper
                // install Swiper modules
                modules={[Navigation, Pagination]}
                pagination={{ clickable: true }}
                id="design-hub-img-gallery"
                spaceBetween={5}
                slidesPerView={1}
                slidesPerGroup={1}
                grabCursor={"true"}
                ref={swiperRef}
                dir={locale === "en" ? "ltr" : "rtl"}
              >
                   <SwiperSlide key={0}>
                  <div className="video-wrapper">
                <video autoPlay playsInline muted loop>
                  <source
                    src={
                      props.data.designCodeVideo?.designCodeVideo?.localFile
                        ?.publicURL
                    }
                    type={props.data.designCodeVideo?.mimeType}
                  />
                </video>
                {props.data.designCodeVideo?.videoLink ? (
                  <div>
                    <ReactModal
                      isOpen={isOpen}
                      onRequestClose={handleModalClose}
                      
                    >
                      <div className="modal-video-movie-wrap">
                        <button className="modal-video-close-btn"  onClick={handleModalClose}></button>
                        <iframe width="460" height="230" src={"https://www.youtube.com/embed/"+props.data.designCodeVideo?.videoLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                      </div>
                    </ReactModal>

                    <div className="play-btn" onClick={() => setOpen(true)}>
                      <img src="/images/play-btn.svg" alt="Play" />
                    </div>
                    <div className="spinner">
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              </SwiperSlide>
                {props.data.designCodeGallery.map((image, index) => (
                  <SwiperSlide key={index+1}>
                    <div className="img-wrapper">
                      <img src={image?.localFile?.publicURL} alt={image.title} />
                    </div>
                  </SwiperSlide> 
                ))}

                {shouldRenderArrows && <SliderNavigation ref={swiperRef} />}

              </Swiper>
              {props.data.designCodeVideo?.aboutTheDesignCode ? (
                <div className="textthemefont"  dangerouslySetInnerHTML={createMarkup(
                  props.data.designCodeVideo?.aboutTheDesignCode
                )} ></div>
              ) : (
                 <div></div>
              )}
            
            </div>
            <div className={hash == '#sustainability'? 'active resizing': 'resizing' } id="sustainability">
             <div className="col-group">
              <div className="col-dt-8">
              {props.data.sustainability?.mainText ? (
                <div className="sustainability-main"  dangerouslySetInnerHTML={createMarkup(
                  props.data.sustainability?.mainText
                )} ></div>
              ) : (
                 <div></div>
              )}
              </div>
              <div className="col-dt-4 ">
                <div className="sustainability-side">
                {props.data.sustainability?.sideText}
                <br />
                {props.data.sustainability?.buttons?.map((item, index) => (
                  <div className="apply-btn">
                      <a href={item.link} target="_blank" rel="noopener noreferrer">
                              {item.text}
                      </a>
                   </div>


                ))}
              </div>
              </div>
             </div>
            </div>
            <div className={hash == '#map'? 'active resizing': 'resizing' } id="the-map">
              <div className="img-wrapper map">
                {locale === "en" ? (
                  <img src="/images/Maps/EN.jpg" alt="Design Hub Map" />
                ) : (
                  <img src="/images/Maps/AR.jpg" alt="Design Hub Map" />
                )}
              </div>
            </div>
            <div className={hash == '#amana'? 'active resizing': 'resizing' } id="amana-map">
            <Tooltip id="my-tooltip-1" className="box1tooltip"/>
            <Tooltip id="my-tooltip-2" className="box1tooltip"/>
            <Tooltip id="my-tooltip-3" className="box1tooltip"/>
            <Tooltip id="my-tooltip-4" className="box1tooltip"/>
            <Tooltip id="my-tooltip-5" className="box1tooltip"/>
            <Tooltip id="my-tooltip-6" className="box1tooltip"/>
            <Tooltip id="my-tooltip-7" className="box1tooltip"/>
            <Tooltip id="my-tooltip-8" className="box1tooltip"/>
            <Tooltip id="my-tooltip-9" className="box1tooltip"/>
            <Tooltip id="my-tooltip-10" className="box1tooltip"/>
              <h1 className="bfont">{string_translation(stringTranslations, "geo_portal_heading", locale)}</h1>
              <h3 className="bfont">{string_translation(stringTranslations, "geo_portal", locale)}</h3>
              <p className="amana-sub">{string_translation(stringTranslations, "geo_portal_sub_heading", locale)}</p>
                    <br />
                              <a className="click-link" href="https://mapservice.alriyadh.gov.sa/geoportal/geomap" target="_blank" rel="noopener noreferrer">{string_translation(stringTranslations, "geo_portal_btn", locale)}</a>
                              <br />&nbsp; <br />
                      <div className="color-code-list">
                        <div className="col-dt-4">
                          <h4 className="bfont"><img className="amana-icon" alt="Icon 1" src="/images/Maps/icon_1.png" /> {string_translation(stringTranslations, "search_box", locale)}</h4>
                          <p className="box-sub-data">{string_translation(stringTranslations, "search_box_data", locale)}</p>
                        </div>
                        <div className="col-dt-4">
                        <h4 className="bfont"><img className="amana-icon" alt="Icon 2" src="/images/Maps/icon_2.png" /> {string_translation(stringTranslations, "plot_number", locale)}</h4>
                        <p className="box-sub-data">{string_translation(stringTranslations, "plot_number_data", locale)}</p>
                        </div>
                        <div className="col-dt-4">
                        <h4 className="bfont"><img className="amana-icon" alt="Icon 3" src="/images/Maps/icon_3.png" /> {string_translation(stringTranslations, "plot_selection", locale)}</h4>
                        <p className="box-sub-data">{string_translation(stringTranslations, "plot_selection_data", locale)}</p>
                        </div>
                      </div>
                      <div className="color-code designcode">
                     <h2 className="bfont">{string_translation(stringTranslations, "design_code_manual", locale)}</h2> 
                     <hr className="hr-line" />
                 
                        <div className="color-code-list list-img">
                      
                        <div  data-tooltip-id="my-tooltip-1"
  data-tooltip-html={"<div><div className='insidebox insidebox1'>SB-T 3.1<br> "+string_translation(stringTranslations, "popup_box", locale)+" <img className='arrow-icon-geo-inside' alt='Arrow Icon' src='/images/arrow_top_right.svg' /></div>T 3.1</div>"}
  data-tooltip-place="top">
                            <div className="geobox" style={{ backgroundColor : 'rgb(245,241,170)'}}>
                            <img className="arrow-icon-geo" alt="Arrow Icon" src="/images/arrow_top_right.svg" /> 
                            </div>
                            <p>T3.1</p>
                          </div>

                           <div  data-tooltip-id="my-tooltip-2"
  data-tooltip-html={"<div><div className='insidebox insidebox2'>SB-T 3.2<br> "+string_translation(stringTranslations, "popup_box", locale)+" <img className='arrow-icon-geo-inside' alt='Arrow Icon' src='/images/arrow_top_right.svg' /></div>T 3.2</div>"}
  data-tooltip-place="top">

 
                            <div className="geobox" style={{backgroundColor : 'rgb(243,201,84)'}}>
                            <img className="arrow-icon-geo" alt="Arrow Icon" src="/images/arrow_top_right.svg" /> 
                            </div>
                            <p>T3.2</p>
                          </div>
                          
                          <div  data-tooltip-id="my-tooltip-3"
  data-tooltip-html={"<div><div className='insidebox insidebox3'>SB-T 4.1<br> "+string_translation(stringTranslations, "popup_box", locale)+" <img className='arrow-icon-geo-inside' alt='Arrow Icon' src='/images/arrow_top_right.svg' /></div>T 4.1</div>"}
  data-tooltip-place="top">
                           <div className="geobox" style={{backgroundColor : 'rgb(178,157,197)'}}>
                           <img className="arrow-icon-geo" alt="Arrow Icon" src="/images/arrow_top_right.svg" /> 
                           </div>
                            <p>T4.1</p>
                          </div>

                          <div  data-tooltip-id="my-tooltip-4"
  data-tooltip-html={"<div><div className='insidebox insidebox4'>SB-T 4.2<br> "+string_translation(stringTranslations, "popup_box", locale)+" <img className='arrow-icon-geo-inside' alt='Arrow Icon' src='/images/arrow_top_right.svg' /></div>T 4.2</div>"}
  data-tooltip-place="top">
                          <div className="geobox" style={{backgroundColor : 'rgb(121,89,156)'}}>
                          <img className="arrow-icon-geo" alt="Arrow Icon" src="/images/arrow_top_right.svg" /> 
                          </div>
                            <p>T4.2</p>
                          </div>


                        

                          <div  data-tooltip-id="my-tooltip-5"
  data-tooltip-html={"<div><div className='insidebox insidebox5'>SB-T 5.1<br> "+string_translation(stringTranslations, "popup_box", locale)+" <img className='arrow-icon-geo-inside' alt='Arrow Icon' src='/images/arrow_top_right.svg' /></div>T 5.1</div>"}
  data-tooltip-place="top">
                            <div className="geobox" style={{ backgroundColor : 'rgb(203,131,176)'}}>
                            <img className="arrow-icon-geo" alt="Arrow Icon" src="/images/arrow_top_right.svg" /> 
                            </div>
                            <p>T5.1</p>
                          </div>


                          <div 
                          data-tooltip-id="my-tooltip-6"
                          data-tooltip-html={"<div><div className='insidebox insidebox6'>SB-T 5.1<br> "+string_translation(stringTranslations, "popup_box", locale)+" <img className='arrow-icon-geo-inside' alt='Arrow Icon' src='/images/arrow_top_right.svg' /></div>T 5.1</div>"}
                          data-tooltip-place="top">
                           <div className="geobox spl2">
                           <img className="arrow-icon-geo" alt="Arrow Icon" src="/images/arrow_top_right.svg" /> 
                           </div>
                            <p>T5.1</p>
                          </div>


                        

                          <div data-tooltip-id="my-tooltip-7"
  data-tooltip-html={"<div><div className='insidebox insidebox7'>SB-T 5.2<br> "+string_translation(stringTranslations, "popup_box", locale)+" <img className='arrow-icon-geo-inside' alt='Arrow Icon' src='/images/arrow_top_right.svg' /></div>T 5.2</div>"}
  data-tooltip-place="top">
                            <div className="geobox" style={{ backgroundColor : 'rgb(234,157,85)'}}>
                            <img className="arrow-icon-geo" alt="Arrow Icon" src="/images/arrow_top_right.svg" /> 
                            </div>
                            <p>T5.2</p>
                          </div>
                       
                          <div data-tooltip-id="my-tooltip-8"
  data-tooltip-html={"<div><div className='insidebox insidebox8'>SB-T 5.3<br> "+string_translation(stringTranslations, "popup_box", locale)+" <img className='arrow-icon-geo-inside' alt='Arrow Icon' src='/images/arrow_top_right.svg' /></div>T 5.3</div>"}
  data-tooltip-place="top">
                           <div className="geobox" style={{backgroundColor : 'rgb(217,63,53)'}}>
                           <img className="arrow-icon-geo" alt="Arrow Icon" src="/images/arrow_top_right.svg" /> 
                           </div>
                            <p>T5.3</p>
                          </div>

                          <div data-tooltip-id="my-tooltip-9"
  data-tooltip-html={"<div><div className='insidebox insidebox9'>SB-T 5.3<br> "+string_translation(stringTranslations, "popup_box", locale)+" <img className='arrow-icon-geo-inside' alt='Arrow Icon' src='/images/arrow_top_right.svg' /></div>T 5.3</div>"}
  data-tooltip-place="top">
                            <div className="geobox spl1">
                            <img className="arrow-icon-geo" alt="Arrow Icon" src="/images/arrow_top_right.svg" /> 
                            </div>
                            <p>T5.3</p>
                          </div>

                          <div data-tooltip-id="my-tooltip-10"
  data-tooltip-html={"<div><div className='insidebox insidebox10'>SB-T 6<br> "+string_translation(stringTranslations, "popup_box", locale)+" <img className='arrow-icon-geo-inside' alt='Arrow Icon' src='/images/arrow_top_right.svg' /></div>T 6</div>"}
  data-tooltip-place="top">
                          <div className="geobox" style={{backgroundColor : 'rgb(113,79,79)'}}>
                          <img className="arrow-icon-geo" alt="Arrow Icon" src="/images/arrow_top_right.svg" /> 
                          </div>
                            <p>T6</p>
                          </div>

                         
                       
                           

                          <div>
                          <div className="geobox" style={{ backgroundColor : 'rgba(255, 0, 0, 0)',border:'none'}}></div>
                            <p>&nbsp;</p>
                          </div>

                         
                    
                        
                         

                          <div>
                          <div className="geobox" style={{ backgroundColor : 'rgba(255, 0, 0, 0)',border:'none'}}></div>
                            <p>&nbsp;</p>
                          </div>
                        </div>
                      

                      </div>
              </div>
          </div>
        
        </div>
      </section>
    </>
  )
}
