import React, { useState, useContext } from "react"
import "./calltoactionbanner.scss"
import {useStaticQuery, graphql} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import { string_translation } from "../../../utils"
import {Link} from "gatsby"

export default function CallToActionBannerDesignCode() {
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      book_ar:translateString(language: AR, string: "BOOK AN APPOINTMENT")
      book_en:translateString(language: EN, string: "BOOK AN APPOINTMENT")

      book_btn_ar:translateString(language: AR, string: "حجز موعد")
      book_btn_en:translateString(language: EN, string: "BOOK APPOINTMENT")

      question_ar:translateString(language: AR, string: "HAVE SOME QUESTIONS")
      question_en:translateString(language: EN, string: "HAVE SOME QUESTIONS")
      
    }
  }`)

  return (
    <>
      <section className="call-to-action designcode">
        <div className="container">
            <div className="call-to-action-wrapper">
                <div className="white-space"/>
                <div className="col-group">
                    <div className="col-dt-6 first-col">
                        <div className="title h2">{string_translation(stringTranslations,"book", locale)}</div>
                        <div className="desc">{string_translation(stringTranslations,"question", locale)}</div>
                    </div>
                    <div className="col-dt-6 second-col">
                        <div className="button-wrapper">
                            <div className="apply-btn">
                            <a href='https://outlook.office365.com/owa/calendar/DesignCodeAppointment@Riyadhalmasar.onmicrosoft.com/bookings/' target="_blank" rel="noopener noreferrer">
                            {string_translation(stringTranslations,"book_btn", locale)}
                            </a>

                            </div>
                            <div className="logo">
                            <img src="/images/call-to-action/logo.svg" alt="Logo" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}
