import React, { useEffect, useContext, useState } from "react"
import ReactModal from 'react-modal'
import { string_translation ,chunk_array, createMarkup} from "../../../utils"
import {useStaticQuery, graphql, Link,navigate} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import "./guidelines.scss"
import attentionIcon from "../../../static/images/error-pages/attention-icon.svg"

// import Swiper core and required modules
import { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"

export default function GuidelinesSustainability(props) {
 
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

   

  const { stringTranslations, site } = useStaticQuery(graphql`query {
    stringTranslations {
      guidlines_ar:translateString(language: AR, string: "DESIGN HUB GUIDLINES")
      guidlines_en:translateString(language: EN, string: "DESIGN HUB GUIDLINES")
      sustainability_manuals_ar:translateString(language: AR, string: "دليل الاستدامة")
      sustainability_manuals_en:translateString(language: EN, string: "Sustainability Manual")
      sort_ar:translateString(language: AR, string: "SORT BY")
      sort_en:translateString(language: EN, string: "SORT BY")
      view_online_ar:translateString(language: AR, string: "VIEW ONLINE")
      view_online_en:translateString(language: EN, string: "VIEW ONLINE")
      
      newest_en:translateString(language: EN, string: "NEWEST")
      newest_ar:translateString(language: AR, string: "NEWEST")
      oldest_en:translateString(language: EN, string: "OLDEST")
      oldest_ar:translateString(language: AR, string: "OLDEST")
      alphabetical_en:translateString(language: EN, string: "ALPHABETICAL")
      alphabetical_ar:translateString(language: AR, string: "ALPHABETICAL")
      fname_ar:translateString(language: AR, string: "FIRST NAME")
      fname_en:translateString(language: EN, string: "FIRST NAME")
      lname_ar:translateString(language: AR, string: "LAST NAME")
      lname_en:translateString(language: EN, string: "LAST NAME")
      email_ar:translateString(language: AR, string: "EMAIL")
      email_en:translateString(language: EN, string: "EMAIL")
      phone_ar:translateString(language: AR, string: "PHONE")
      phone_en:translateString(language: EN, string: "PHONE")
      msg_ar:translateString(language: AR, string: "صفة المستفيد")
      msg_en:translateString(language: EN, string: "ROLE TYPE")
      submit_ar:translateString(language: AR, string: "SUBMIT")
      submit_en:translateString(language: EN, string: "SUBMIT")


      selectowner_ar:translateString(language: AR, string: "مالك")
      selectowner_en:translateString(language: EN, string: "Owner")
      selectengoff_ar:translateString(language: AR, string: "مكتب هندسي / معماري")
      selectengoff_en:translateString(language: EN, string: "Engineering Office / Architect")
      selectpbuyer_ar:translateString(language: AR, string: "مشتري محتمل")
      selectpbuyer_en:translateString(language: EN, string: "Potential Buyer")
      selectagent_ar:translateString(language: AR, string: "وكالة / وكيل")
      selectagent_en:translateString(language: EN, string: "Agency / Agent ")
      selectother_ar:translateString(language: AR, string: "أخرى")
      selectother_en:translateString(language: EN, string: "Other")
      loading_ar:translateString(language: AR, string: "LOADING")
      loading_en:translateString(language: EN, string: "LOADING")
      ihaveread_ar:translateString(language: AR, string: "أقر بأنني قد قرأت ووافقت على")
      ihaveread_en:translateString(language: EN, string: "I have read and agree to the")
      ppolicy_ar:translateString(language: AR, string: "سياسة الخصوصية")
      ppolicy_en:translateString(language: EN, string: "Privacy Policy.")
    }
    site {
      siteMetadata {
        formSubmissionUrl
      }
    }
  }`)

  const swiperRef = React.useRef(null)
  const gallery = props.data
  const [adata, setData] = useState([])
  const [sortType, setSortType] = useState("newest")
  const [isOpen, setOpen] = useState(false)
  const shouldRenderArrows = adata.length > 4
  let chunked_array = chunk_array(gallery, 4)
  useEffect(() => {
    const sortArray = type => {
      const types = {
        alphabetical: "guidelinesTitle",
        newest: "guidelinesImage",
        oldest: "guidelinesImage",
      }
      const sortProperty = types[type]
      const sorted = [...gallery].sort((a, b) => {
        if (sortProperty === "guidelinesImage" && sortType === "newest") {
          return b?.guidelinesImage?.date.localeCompare(a?.guidelinesImage?.date)
        } else if (
          sortProperty === "guidelinesImage" &&
          sortType === "oldest"
        ) {
          return a?.guidelinesImage?.date.localeCompare(b?.guidelinesImage?.date)
        } else {
          return b[sortProperty] < a[sortProperty]
        }
      })
      setData(sorted)
    }
    sortArray(sortType)
  }, [sortType])
  
  const handleModalOpen = event => {
   
    setFormState({
      ...formState,
      ['file']: event.currentTarget.dataset.file
    })
    setOpen(true)
  }
 

  const handleModalClose = event => {
    setOpen(false)
  }

  ReactModal.setAppElement('#___gatsby')
  let showModal = true
  const [formState, setFormState] = useState({})
  const handleFormSubmit = (e) => {
    formSubmitHandler(e, formState)
  } 

  const handleFieldChange = (field, e) => {
    setFormState({
      ...formState,
      [field]: e.target.value,
    })
  }
  const ErrorMessages = (errors) => {
    return (
      <div className="errorMessageContainer">
        <div className="errorTitleContainer">
          <img src={attentionIcon} className="attentionIcon" alt={string_translation(stringTranslations, "submission_error", locale)} />
          <span className="errorMessage">{string_translation(stringTranslations, "submission_error", locale)}</span>
        </div>
        <div className="errorBody">
          <p>{errors.errors}</p>
         
          <span className="tryAgain">{string_translation(stringTranslations, "try_again", locale)}</span>
        </div>
      </div>
    );
  }
  const formSubmitHandler = async (event, payload) => {
    event.preventDefault()
   

   
    const result = await executeRecaptcha('contact')
    var fieldname = "g-recaptcha-response";
    payload[fieldname] = result;
    payload['lang'] = locale;
    setLoading(true)
    setError(null)
    setValidationErrors(null)

    fetch('/manualdownload/manual', {
      method: "POST",
      body: jsonToFormData(payload),
    })
    .then((resp) => resp.json())
      .then((resp) => resp)  .then((resp) => {
        if (resp.status !== "success") {
            setValidationErrors(resp.message)
            throw resp.message
        }else{   
        navigate("/"+locale+'/success-download?email='+payload.email)
        }
      })
      .catch((error) => {
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const [isSent, setSent] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [hasError, setError] = useState(null)
  const [validationErrors, setValidationErrors] = useState(null)
  const apiUrl =site.siteMetadata.formSubmissionUrl
 const { executeRecaptcha } = useGoogleReCaptcha()
 const jsonToFormData = (json) => {
  try {
    const data = new FormData()

    for (let k in json) {
      data.append(k, json[k])
    }

    return data
  } catch (error) {
    console.error(error)
    return null
  }
}
  return (
    <section className="guidlines-section infographics guidelines" id="sustainability-manuals">
      <div className="container">
        <div className="heading-wrapper">
          <div className="heading h2">{string_translation(stringTranslations,"sustainability_manuals", locale)}</div>
          {/* <div className="sorting-wrapper">
            <div className="sorting-tag">
              <div className="text">{string_translation(stringTranslations,"sort", locale)}</div>
              <select onChange={e => setSortType(e.target.value)}>
                <option value="newest">{string_translation(stringTranslations,"newest", locale)}</option>
                <option value="oldest">{string_translation(stringTranslations,"oldest", locale)}</option>
                <option value="alphabetical">{string_translation(stringTranslations,"alphabetical", locale)}</option>
              </select>
              <div className="dropdown-arrow">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12.7891" r="12" fill="#3D3935" />
                  <path
                    d="M11.4999 15.7891L11.88 15.4407L16 11.7245L15.2398 10.7891L11.5 14.1633L7.76023 10.7891L7 11.7245L11.12 15.4407L11.4999 15.7891Z"
                    fill="white"
                    stroke="white"
                    strokeWidth="0.5"
                  />
                </svg>
              </div>
            </div>
            <div className="sorting-tags"></div>
          </div> */}
        </div>
          </div>
        <div className="big-slider-wrapper">
        <div className="container">
        {props?.maintext ? (
                <div className="textthemefonthead"  dangerouslySetInnerHTML={createMarkup(
                  props?.maintext
                )} ></div>
              ) : (
                 <div></div>
              )}
        {chunked_array.map((guildline_array, index) => (
            <div className="col-group-wrapper" key={index}>
            {guildline_array.map((guidline, index) => (
               <div className="col-dt-3" key={index}>
               <div className="img-wrapper with-borders img-guide">
                  <img
                    src={guidline?.guidelinesImage?.localFile?.publicURL}
                    alt={guidline?.guidelinesTitle}
                  />
                </div>
                <div className="title titleguide h2">{guidline?.guidelinesTitle}</div>
                <div className="action-section">
                  <div className="download-wrapper">
                    <div className="download-img-wrapper">
                      <a href='#' data-file={guidline?.guidelinesTitle} onClick={handleModalOpen}   rel="noopener noreferrer">
                        <img src="/images/infographics/download.svg" alt={guidline?.guidelinesTitle+" Download"} />
                      </a>
                    </div>
                  </div>
               
                </div>
               </div>
             
            ))}

           
          </div>
          ))}
        </div>
      </div>
      <ReactModal className="downloadModal"
                      isOpen={isOpen}
                      onRequestClose={handleModalClose}
                      
                    >
                    
<div className="get-in-touch">
<button className="modal-video-close-btn"  onClick={handleModalClose}></button>
   <div className="form-wrapper">                      <div className="get-in-touch-form-wrapper">
                      <div>
                      <form onSubmit={handleFormSubmit}>
      {/* <div>isLoading: {isLoading ? "Loading" : "false"}</div>
      <div>isSent: {isSent ? "Sent" : "false"}</div>
      <div>Error: {hasError || "null"}</div> */}



      <div className="errorsParent">{validationErrors ? <ErrorMessages errors={validationErrors} /> : ""}</div>

      <div className="get-in-touch-form">
        <div className="logo">
          <div className="desktop-show">
            <img
              src="/images/invest/form-logo-2.svg"
              alt="Invest"
            />
          </div>
          <div className="mobile-show">
            <img
              src="/images/invest/form-logo-mobile-2.svg"
              alt="Invest"
            />
          </div>
        </div>
        <div className="all-labels-wrappers">
          <div className="label-wrapper">
            <div className="col-group">
              <div className="col-dt-6 label-1">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations, "fname", locale)}
                  // value={firstname}
                  autoComplete="name"
                  required="required"
                  onChange={(e) => handleFieldChange("fname", e)}
                />
              </div>
              <div className="col-dt-6 label-2">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations, "lname", locale)}
                  // value={lastname}
                  required="required"
                  onChange={(e) => handleFieldChange("lname", e)}
                />
              </div>
            </div>
          </div>
          <div className="label-wrapper">
            <div className="col-group">
              <div className="col-dt-6 label-1">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations, "email", locale)}
                  // value={email}
                  required="required"
                  autoComplete="home email"
                  onChange={(e) => handleFieldChange("email", e)}
                />
              </div>
              <div className="col-dt-6 label-2">
                <input
                  type="text"
                  placeholder={string_translation(stringTranslations, "phone", locale)}
                  // value={number}
                  required="required"
                  autoComplete="home tel"
                  onChange={(e) => handleFieldChange("phone", e)}
                />
              </div>
            </div>
          </div>

          <div className="label-wrapper last-label-wrapper">
            <div className="label">
              
              <select placeholder={string_translation(stringTranslations, "msg", locale)}
                // value={message}
                required="required"
                onChange={(e) => handleFieldChange("msg", e)}  >
                   <option value="" disabled selected>{string_translation(stringTranslations, "msg", locale)}</option>
                <option>{string_translation(stringTranslations, "selectowner", locale)}</option>
<option>{string_translation(stringTranslations, "selectengoff", locale)}</option>
<option>{string_translation(stringTranslations, "selectpbuyer", locale)}</option>
<option>{string_translation(stringTranslations, "selectagent", locale)}</option>
<option>{string_translation(stringTranslations, "selectother", locale)}</option>
                </select>
            </div>
          </div>
          <div className="label-wrapper last-label-wrapper">
          
          <input type="checkbox" required="required" name="pplocy" /> {string_translation(stringTranslations, "ihaveread", locale)} <a href={"/"+locale+"/privacy-policy"} >{string_translation(stringTranslations, "ppolicy", locale)}</a>
        
        </div>
          <div className="label-wrapper">
            <div className="submit-btn-wrapper">
              <div className="submit-btn">
                <button className="cta-btn" type="submit" disabled={isLoading}>{isLoading ? string_translation(stringTranslations, "loading", locale) : string_translation(stringTranslations, "submit", locale)}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    
    </div> </div></div></div>
                    </ReactModal>
    </section>

    
  )
}
