import * as React from "react"
import "../styles/style.scss"
import "../styles/css/swiper-bundle.min.css"
import Seo from '../components/seo'
import Layout from "../components/layout"
import WhoWeAre from "../components/WhoWeAre"
import ComboWrapper from "../components/ComboWrapper"
import Faq from "../components/Faq"
import Guidelines from "../components/Guidelines"
import Appointment from "../components/BookAppointment"
import CallToActionBanner from "../components/CallToActionBanner"
import { graphql } from "gatsby"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import GuidelinesForm from "../components/GuidelinesForm"
import CallToActionBannerDesignCode from "../components/CallToActionBannerDesignCode"
import GuidelinesSustainability from "../components/GuidelinesSustainability"
import GuidelinesSupporting from "../components/GuidelinesSupporting"
import FaqSustainability from "../components/FaqSustainability"
import GetinTouchDesignCode from "../components/GetinTouchDesignCode"
export default function DesignHub(pageData) {
  const data = pageData?.data
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri

  return (
    <>
      <Seo data={seo}/>
      <Layout translatedURI={translatedURI}>
      <WhoWeAre
          title={data.wpPage.pageSubtitle?.subtitle}
          name={data.wpPage?.title}
          description={data.wpPage.pageDescription?.pageDescription}
        />
      
        <ComboWrapper data={data?.wpPage?.designCodeGuidlines} locale={locale}/>
        {(data?.wpPage?.designCodeGuidlines?.designCodeGuidlines)?<Guidelines data={data?.wpPage?.designCodeGuidlines?.designCodeGuidlines} maintext={data?.wpPage?.designCodeGuidlines?.transectManualsText} locale={locale}/>:''}
        {(data?.wpPage?.designCodeGuidlines?.sustainabilityManuals)?<GuidelinesSustainability data={data?.wpPage?.designCodeGuidlines?.sustainabilityManuals} maintext={data?.wpPage?.designCodeGuidlines?.sustainabilityManualText} locale={locale}/>:''}
        {(data?.wpPage?.designCodeGuidlines?.supportingManuals)?<GuidelinesSupporting data={data?.wpPage?.designCodeGuidlines?.supportingManuals} maintext={data?.wpPage?.designCodeGuidlines?.supportingManualsText} locale={locale}/>:''}
        {(data?.wpPage?.designCodeGuidlines?.forms)?<GuidelinesForm data={data?.wpPage?.designCodeGuidlines?.forms} maintext={data?.wpPage?.designCodeGuidlines?.formsAndRequirementsText} locale={locale}/>:''}

        <Faq data={data.wpPage.designCodeGuidlines.designCodeFaq} />
        <FaqSustainability data={data.wpPage.designCodeGuidlines.sustainabilityFaq} />
        <CallToActionBannerDesignCode/>
        {/* {(data?.wpPage?.designCodeGuidlines?.callToActionBanner)?<CallToActionBanner data={data?.wpPage?.designCodeGuidlines?.callToActionBanner}/>:''} */}

        <GetinTouchDesignCode data={data?.wpPage?.designCodeGuidlines?.contactForm} locale={locale}/>


      </Layout> 

    </>
  )
}

export const pageQuery = (graphql` query designHubData($databaseId:Int) {
  wpPage(databaseId: {eq: $databaseId}) {
    id
    title
    pageSubtitle {
      fieldGroupName
      subtitle
    }
    date
    pageDescription {
      fieldGroupName
      pageDescription
    }
    designCodeGuidlines {
    contactForm
      designCodeFaq {
        description
        fieldGroupName
        title
      }
      sustainabilityFaq {
        description
        fieldGroupName
        title
      }
      designCodeGallery {
        localFile {
          publicURL
        }
        title
      }
      designCodeGuidlines {
        guidelinesLink
        guidelinesTitle
        guidelinesImage {
          localFile {
            publicURL
          }
          date
        }
        guidelinesDownloadLink
      }
      sustainabilityManuals {
        guidelinesLink
        guidelinesTitle
        guidelinesImage {
          localFile {
            publicURL
          }
          date
        }
        guidelinesDownloadLink
      }
      supportingManuals {
        guidelinesLink
        guidelinesTitle
        guidelinesImage {
          localFile {
            publicURL
          }
          date
        }
        guidelinesDownloadLink
      }
      forms {
        guidelinesLink
        guidelinesTitle
        guidelinesImage {
          localFile {
            publicURL
          }
          date
        }
        guidelinesDownloadLink
      }
      sustainability {
        buttons {
          link
          text
        }
        mainText
        sideText
      }
      designCodeVideo {
        designCodeVideo {
          localFile {
            publicURL
          }
          title
          mimeType
          description
        }
        videoLink
        aboutTheDesignCode
      }
      transectManualsText
      sustainabilityManualText
      supportingManualsText
      formsAndRequirementsText
      callToActionBanner {
        buttonLink
        buttonText
        description
        title
      }
    }
  }
}`);
