import React, {useContext,useState } from "react"
import "./faq.scss"
import { createMarkup, string_translation } from "../../../utils"
import {useStaticQuery, graphql } from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

export default function Faq(props) {
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  const [formdState, setformdState] = useState(false)
  const { stringTranslations } = useStaticQuery(graphql`
    query {
      stringTranslations {
        faq_ar:translateString(language: AR, string: "FAQ")
        faq_en:translateString(language: EN, string: "FAQ")
        design_code_ar:translateString(language: AR, string: "كود التصميم")
        design_code_en:translateString(language: EN, string: "Design Code")
      }
    }
  `)

  const hideotherform = () => {
    var divsToHide = document.getElementsByClassName("FaqSustainabilityHide"); 
    for(var i = 0; i < divsToHide.length; i++){
        divsToHide[i].style.display = "none"; 
    }
    document.getElementById("FaqDesignCodeSection").style.display = "none";
    document.getElementById("FaqSustainabilitySection").style.display = "none";
    document.getElementsByClassName("arrowbuttonDesignCode")[0].style.color="#FFFFFF";
    document.getElementsByClassName("arrowbuttonSustainability")[0].style.color="#FFFFFF";
    if(formdState==null || formdState==undefined || !formdState){
      document.getElementsByClassName("arrowbuttonDesignCode")[0].style.color="#000000";
    document.getElementById("FaqDesignCodeSection").style.display = "block";
    }else{
      document.getElementsByClassName("arrowbuttonSustainability")[0].style.color="#FFFFFF";
      document.getElementById("FaqSustainabilitySection").style.display = "none";
    }

if(locale=="ar"){

  if(formdState==null || formdState==undefined || !formdState){
    var divsToHide = document.getElementsByClassName("leftarrowbuttonimageDesignCodear"); 
    for(var i = 0; i < divsToHide.length; i++){
        divsToHide[i].style.display = "none"; 
    }

    var divsToshow = document.getElementsByClassName("downarrowbuttonimageDesignCodear"); 
    for(var i = 0; i < divsToshow.length; i++){
      divsToshow[i].style.display = "block"; 
    }

  }else{
    var divsToHide = document.getElementsByClassName("leftarrowbuttonimageDesignCodear"); 
    for(var i = 0; i < divsToHide.length; i++){
        divsToHide[i].style.display = "block"; 
    }

    var divsToshow = document.getElementsByClassName("downarrowbuttonimageDesignCodear"); 
    for(var i = 0; i < divsToshow.length; i++){
      divsToshow[i].style.display = "none"; 
    }
  }
 
  var divsToHide = document.getElementsByClassName("downarrowbuttonimageSustainabilityar"); 
  for(var i = 0; i < divsToHide.length; i++){
      divsToHide[i].style.display = "none"; 
  }
 
  

 

  var divsToshow = document.getElementsByClassName("leftarrowbuttonimageSustainabilityar"); 
  for(var i = 0; i < divsToshow.length; i++){
    divsToshow[i].style.display = "block"; 
  }
 
}else{


  if(formdState==null || formdState==undefined || !formdState){
    var divsToHide = document.getElementsByClassName("rightarrowbuttonimageDesignCodeen"); 
    for(var i = 0; i < divsToHide.length; i++){
        divsToHide[i].style.display = "none"; 
    }

    var divsToshow = document.getElementsByClassName("downarrowbuttonimageDesignCodeen"); 
    for(var i = 0; i < divsToshow.length; i++){
      divsToshow[i].style.display = "block"; 
    }

  }else{
      var divsToHide = document.getElementsByClassName("rightarrowbuttonimageDesignCodeen"); 
      for(var i = 0; i < divsToHide.length; i++){
          divsToHide[i].style.display = "block"; 
      }
  
      var divsToshow = document.getElementsByClassName("downarrowbuttonimageDesignCodeen"); 
      for(var i = 0; i < divsToshow.length; i++){
        divsToshow[i].style.display = "none"; 
      }


  }
 

  var divsToHide = document.getElementsByClassName("downarrowbuttonimageSustainabilityen"); 
  for(var i = 0; i < divsToHide.length; i++){
      divsToHide[i].style.display = "none"; 
  }
 



  var divsToshow = document.getElementsByClassName("rightarrowbuttonimageSustainabilityen"); 
  for(var i = 0; i < divsToshow.length; i++){
    divsToshow[i].style.display = "block"; 
  }

}

if(formdState==null || formdState==undefined || !formdState){
setformdState(true);
}else{
  setformdState(false);
}
  }

  return (
    <>
      <section className="faq-main">
        <div className="get-in-touch">
          <div className="container">
            <div className="form-wrapper">
              <div className="form-wrapper nopadform">
              <a className="text-no-decor" onClick={hideotherform}  ><div onClick={hideotherform}  className="big-title h2 arrowbuttonDesignCode">{string_translation(stringTranslations,"design_code", locale)} {string_translation(stringTranslations,"faq", locale)}
              <img className={"leftarrowbuttonimageDesignCode"+locale} src="/images/contact-us/leftw.png"  alt="Arrow Icon"/>
              <img className={"rightarrowbuttonimageDesignCode"+locale} src="/images/contact-us/rightw.png"  alt="Arrow Icon"/>
              <img className={"downarrowbuttonimageDesignCode"+locale} src="/images/contact-us/downb.png"  alt="Arrow Icon"/>
              </div></a>
              <div className="get-in-touch-form-wrapper FaqSustainabilityHide" id="FaqDesignCodeSection">
              <div className="get-in-touch-form-wrapper">
                <div className="get-in-touch-form">
                  <div className="logo">
                    <div className="desktop-show">
                      <img src="/images/design-hub/faq-logo-outline.svg" alt="Faq" />
                      <center><p className="faq-head">{string_translation(stringTranslations,"design_code", locale)}</p></center>
                    </div>
                  </div>
                  <div className="all-labels-wrappers">
                    {props.data.map((item, index) => (
                      <div className="custom-collapsible-faq" key={index}>
                        <div className="collapsible-header-faq">
                          {item.title}
                        </div>
                        <div className="collapsible-body-faq" dangerouslySetInnerHTML={createMarkup(item?.description)}></div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              </div>
            </div>
             
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
